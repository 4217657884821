<template>
  <div>
    <Quizzes :selectedPageTemplate="selectedPageTemplate" />
  </div>
</template>

<script>
export default {
  components: {
    Quizzes: () => import("@/components/Design/View/ViewTheme")
  },
  data: () => ({
    selectedPageTemplate: "Quizzes"
  }),
  created() {}
};
</script>
